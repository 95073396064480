import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Current Projects`}</h2>
    <ProjectCard title="Slack bots in Go" link="https://github.com/kirillito/panda-bot" bgSchema="cold" mdxType="ProjectCard">
  Chat bot I'm writing for my team Slack channel.<br />
  I was curious about learning Go language, so after doing a few tutorials, I've decided to jump right into the code and write something useful and fun. 
  This project is still very young, but I plan to improve it and branch it off into more bots with NLP support using Prose Go library.<br /><br />
  Tech stack: Go language, BoltDB NoSQL DB, <br />Slack Real Time Messaging API<br /><br />
  <img src="images/golang.svg" width="60px" alt="Go Language logo" />
  <img src="images/boltdb.png" width="60px" alt="BoltDB logo" />
  <img src="images/slack.svg" width="60px" alt="Slack logo" />
    </ProjectCard>
    <ProjectCard title="Video Games in JavaScript" link="https://kirillito.github.io/racing-game/" bgSchema="cold" mdxType="ProjectCard">
  I love writing games, my very first finished program was Arkanoid/Breakout game written in QBASIC. It is one of my favourite hobbies.
  On my GitHub you can find Breackout, Tetris, Racing and other remakes of classical games. I'm currently working on adding multiplayer support to some of them using WebSockets, and additional chat capabilities with server-side logic written in Go.<br /><br />
  Tech stack: HTML5, JavaScript<br /><br />
  <img src="images/html5.svg" width="60px" alt="HTML5 logo" />
  <img src="images/javascript.svg" width="42px" alt="JavaScript logo" />
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      