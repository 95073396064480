/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { hidden } from "../styles/utils"

const icons = {
  star1: {
    shape: (
      <g transform="matrix(.13393 0 0 .13393 167.72 -40.805)">
        <path d="m-902.32 304.68-28.04 302.41-115.18-95.635 95.635 115.18-302.41 28.04 302.41 28.039-95.636 115.18 115.18-95.634 28.04 302.41 28.039-302.41 115.18 95.635-95.635-115.18 302.41-28.039-302.41-28.04 95.634-115.18-115.18 95.636z"/>
      </g>
    ),
    viewBox: `0 0 100 100`,
  },
  star2: {
    shape: (
      <path d="M251,12.093l15.1,177.088,50.451-97.445L283.323,196.32l135.9-114.55-114.551,135.9,104.577-33.231L311.815,234.9,488.912,250,311.815,265.1l97.438,50.462L304.676,282.325,419.227,418.23l-135.9-114.55,33.23,104.584L266.1,310.819,251,487.907l-15.1-177.1-50.453,97.446L218.67,303.68,82.766,418.23,197.315,282.325,92.74,315.558,190.185,265.1,13.088,250,190.179,234.9,92.74,184.442l104.575,33.231L82.766,81.77l135.9,114.55L185.447,91.736,235.9,189.19,251,12.093h0Z"/>
    ),
    viewBox: `0 0 500 500`,
  },
  cloud1: {
    shape: (
      <g transform="matrix(.035446 0 0 -.035446 -3.5482 240.57)">
        <path d="m6660 6784c-19-2-82-9-140-15-523-56-1058-274-1490-610-121-94-319-286-427-414-206-244-392-573-487-862-15-46-32-83-37-83s-31 11-57 24c-312 156-733 116-1022-99-70-52-183-172-234-250-98-148-151-302-163-472-3-51-9-93-12-93s-67 9-141 20c-717 106-1450-97-1915-532-324-302-479-702-424-1094 42-301 221-611 482-830 424-357 994-534 1623-503 326 16 632 91 910 224l111 52 33-81c182-454 580-785 1070-892 130-29 470-27 596 3 279 68 504 188 696 372l98 94 81-37c466-213 1035-232 1512-50 119 46 303 142 389 205 37 27 71 49 74 49 4 0 43-35 88-78 374-357 917-583 1471-610 545-27 1034 103 1470 393 132 87 305 235 396 336 55 62 183 225 207 264 5 8 36 10 97 6 225-14 436 26 628 120 477 234 733 745 630 1259-21 105-48 180-104 290-171 334-500 567-884 627-125 19-325 12-438-16l-78-19-97 100c-343 354-817 595-1314 668l-87 13-6 46c-43 341-117 595-260 886-139 284-307 519-529 740-456 454-1040 739-1697 830-107 15-539 28-619 19z"/>
      </g>
    ),
    viewBox: `0 0 450 232.82`,
  },
  cloud2: {
    shape: (
      <g transform="matrix(.035171 0 0 -.035171 .30794 293.5)">
        <path d="m6065 8353c-409-32-727-108-1061-253-452-196-821-497-1067-870-154-233-259-521-285-783l-7-71-155-22c-269-38-474-100-705-214-630-310-1033-863-1096-1503-6-64-9-155-8-202l4-85h-62c-86 0-265-26-379-55-329-83-606-243-835-482-196-205-321-440-381-716-31-146-31-408 0-553 61-282 198-530 412-744 58-58 148-135 200-172 249-176 581-300 871-324 75-7 82-9 91-33 36-90 104-221 143-275 54-76 179-197 255-247 231-152 499-201 765-139 152 36 278 98 404 201l64 52 21-29c12-16 51-63 87-104 334-380 804-626 1348-707 145-22 503-24 636-4 115 17 261 47 350 72 344 99 697 301 919 527l58 60 87-64c166-124 358-205 561-239 144-25 375-16 511 19 366 93 653 316 822 638 26 51 44 75 51 69 23-18 184-83 297-121 128-42 298-81 449-101 122-17 458-17 580 0 751 102 1374 523 1665 1126 113 234 162 432 172 693l6 163 86 57c291 192 526 452 676 752 333 664 208 1438-320 1989-357 373-828 600-1374 662-132 15-513 6-630-15l-85-15-70 67c-110 104-192 169-311 248-281 185-608 306-947 352l-130 17-45 88c-293 567-899 1011-1632 1197-307 78-719 116-1006 93z"/>
      </g>    
    ),
    viewBox: `0 0 450 293.84`,
  }
}

type IconType = "star1" | "star2" | "cloud1" | "cloud2"

type SVGProps = {
  stroke?: boolean
  color?: string | number | any
  width: number
  icon: IconType
  left: string
  top: string
  hiddenMobile?: boolean
}

const defaultProps = {
  stroke: false,
  hiddenMobile: false,
}

const SVG = ({ stroke, color, width, icon, left, top, hiddenMobile }: SVGProps) => (
  <svg
    sx={{
      position: `absolute`,
      stroke: stroke ? `currentColor` : `none`,
      fill: stroke ? `none` : `currentColor`,
      display: hiddenMobile ? hidden : `block`,
      color,
      width,
      left,
      top,
    }}
    viewBox={icons[icon].viewBox}
  >
    {icons[icon].shape}
  </svg>
)

export default SVG

SVG.defaultProps = defaultProps 